import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], {type: 'region'});

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    setFetchAttempted(true); // Set to true as fetch begins
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          // If city is "unknown", fetch from the external API
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData); // Log the external API data
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          // Use the local API data
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase())); // Assumes regionNames is defined and imported
          setCity(decodedCity);
          console.log(data); // Log the local API data
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
        // Optionally handle the error by fetching from external API or other means
      });
  
  }, []);

  useEffect(() => {
    // Store the original body style
    const originalStyle = document.body.style.cssText;
  
    // Set the new background style
    document.body.style.backgroundImage = "url('https://i.ibb.co/94dykKW/bg.gif')";
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.minHeight = '100vh';
  
    // Reset to the original style on component unmount
    return () => {
      document.body.style.cssText = originalStyle;
    };
  }, []);

  const imageUrls = [
    'https://i.ibb.co/SVMRnkk/cropped-Photo-Jan-31-2024-12-08-53-PM-1.jpg',
    'https://i.ibb.co/qDc8ZhN/cropped-Photo-Jan-10-2024-7-39-22-PM.jpg',
    'https://i.ibb.co/tptc9hm/cropped-Photo-Jan-31-2024-12-05-25-PM-1.jpg',
    'https://i.ibb.co/mb7Y4M4/cropped-Photo-Jan-11-2024-10-28-16-AM.jpg',
    'https://i.ibb.co/NCdkm9Z/cropped-Photo-Jan-10-2024-7-39-32-PM.jpg',
    'https://i.ibb.co/xqbKmKw/cropped-Photo-Feb-21-2024-4-35-55-PM.jpg',
    'https://i.ibb.co/SVMRnkk/cropped-Photo-Jan-31-2024-12-08-53-PM-1.jpg',
    'https://i.ibb.co/qDc8ZhN/cropped-Photo-Jan-10-2024-7-39-22-PM.jpg',
    'https://i.ibb.co/tptc9hm/cropped-Photo-Jan-31-2024-12-05-25-PM-1.jpg',
    'https://i.ibb.co/mb7Y4M4/cropped-Photo-Jan-11-2024-10-28-16-AM.jpg',
    'https://i.ibb.co/NCdkm9Z/cropped-Photo-Jan-10-2024-7-39-32-PM.jpg',
    'https://i.ibb.co/xqbKmKw/cropped-Photo-Feb-21-2024-4-35-55-PM.jpg'
  ];

  return (


    <div className="container">
    <img 
      src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
      alt="Header Image"
      className="header-image"
    />
    <Swiper
      effect={'coverflow'}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      slidesPerView={'2'}
      coverflowEffect={{
      rotate: 15,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
     }}
    autoplay={{ // Corrected from autoPlay to autoplay
      delay: 2000,
      disableOnInteraction: false,
    }}
    breakpoints={{
      // Screens smaller than 640px
      640: {
        slidesPerView: 2,
      },
      // Larger screens
      640: {
        slidesPerView: 3, // or whatever number you prefer
      },
    }}
    modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
    className="swiper-container"
  >
    {imageUrls.map(url => (
      <SwiperSlide key={url} className="swiper-slide">
        <img src={url} alt="slide_image"/>
      </SwiperSlide>
    ))}
      <div className="slider-controler">
        <div className="swiper-pagination"></div>
      </div>
    </Swiper>

    <div className='textContainer'>
      <img src='https://i.ibb.co/tLy5mVk/cropped-Photo-Jan-31-2024-12-08-53-PM-1-modified.png'/>
      <section>
          <div className='flexRow'>Gigii bunny 🐰 <div className='buttonStyling'>Online Now</div></div>
          <div className='flexRow'>
            <span>
            Your fav latin e-girl currently in {city}, {region}   {country && <img 
                src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                alt={`${country} Flag`}
                style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
              />} 
          
              
            </span>
          </div>


      </section>
    </div>

    <a href="https://ofmsites.pro/gigiinsta" id="customButton">
      Send me a message
    </a>

    <p className="exclusiveContent">
    <span>
    Exclusive Content🔥 70% OFF For 10 PEOPLE ONLY <br/><br/>
    Looking for content partners while im travelling, message me on Onlyfans if your close and want to shoot spicy content 👀
              </span>
    </p>
    <Analytics/>
  </div>


  );
}

export default App;
